import { createSelector } from 'reselect';
import last from 'lodash/last';

export const selectCurrentUser = state => state.user.currentUser;

export const selectCurrentUserId = state => state.user.currentUser.sub;

export const selectCookieConsent = state => state.user.cookieConsent;

export const selectOrganizations = state => state.user.organizations;

export const selectCurrentOrganization = createSelector(
  [selectOrganizations],
  organizations => last(organizations)
);
